import React, { useState, useMemo } from 'react';
import { Formik, Form } from 'formik';

import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from 'styled-components';
import {
    Elements,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
// Create the Stripe object yourself...
import * as coreAPI from '../../coreApi';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link, withRouter } from 'react-router-dom';
import CheckBoxInfo from '../../newComponents/checkbox';
import styled from 'styled-components';
import { IoIosInformationCircle } from 'react-icons/io';

import landscapeIcon from '../../assets/icons/mountainsfull.svg';
import StepForm from './StepForm';
import PaymentSuccess from './PaymentSuccess';

const StepFourSection = styled.section`
    margin-top: 60px;

    max-width: 730px;
    margin-left: auto;
    .form-n-summary {
        gap: 80px;
        .summary-box {
            border-radius: 8px;
            min-width: 240px;
            padding: 12px 16px;
            box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.07);
            h5 {
                line-height: 180%;
                font-weight: bold;
                margin-bottom: 12px;
            }
            .plan-detail {
                .plan-left {
                    padding-bottom: 12px;
                    border-bottom: 1px solid #ddedd0;
                    img {
                        width: 100%;
                        max-width: 64px;
                        margin-bottom: 7px;
                    }
                    .plan-title {
                        font-size: 16px;
                        line-height: 29px;
                        font-weight: 600;
                    }
                    .fee-in {
                        line-height: 180%;
                        .fee {
                            font-weight: 600;
                        }
                    }
                    .landscape-img {
                        max-width: 42px;
                        margin-top: 15px;
                        margin-bottom: 7px;
                    }
                }
                .plan-right {
                    padding-top: 12px;
                    .total-due {
                        .due-text {
                            font-weight: 600;
                            line-height: 180%;
                        }
                        .amount {
                            margin-top: 4px;
                            font-size: 32px;
                            line-height: 42px;
                            font-weight: 600;
                        }
                    }
                }
            }
            .tooltip-box {
                display: none;
                font-size: 14px;
                padding: 20px;
                box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                background: white;
                border-radius: 8px;
                position: absolute;
                font-weight: 400;
                right: 0;
                line-height: 17px;
                width: 190px;
            }
            .tooltip-ic {
                font-size: 18px;
                width: 20px;
                height: 20px;
                display: inline-block;
                line-height: 12px;
                color: ${({ theme }) => theme.colors.title_green};
                &:hover + .tooltip-box {
                    display: block;
                }
            }
        }
    }
    .form-section {
        gap: 12px;
        .card-num-box {
            font-size: 16px;
            height: 37px;
            width: 100%;
            padding: 10px 12px;
            border: 1px solid #cecece;
            border-radius: 6px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.title_green};
            &:focus {
                outline: 0;
            }
            &::placeholder {
                color: ${({ theme }) => theme.colors.lightGray};
            }
        }
        .expiry-box {
            flex: 1 0 65%;
        }
        .cvc-box {
            width: 100%;
        }

        .form-check {
            input[type='checkbox'] {
                width: 25px;
                height: 25px;
            }
        }
    }

    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 40px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
        .powerby {
            box-shadow: none;
            width: 100%;
            top: 0;
            left: 0;
            gap: 2px;
            font-style: italic;
            max-width: none;
            .tooltip-ic {
                width: 20px;
                height: 20px;
            }
            .tooltip-box {
                bottom: 30px;
                font-style: normal;
            }
        }
    }

    .message-card {
        max-width: 100%;
        margin: 1rem 0;
        padding: 1rem;
        background-color: ${({ theme }) => theme.colors.sand};
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        opacity: 0;
        display: none;
        transition: opacity 0.5s ease-in-out;
    }

    .message-card.visible {
        opacity: 1;
        display: block;
    }

    .message-card p {
        margin: 0;
        font-size: 14px;
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .form-section {
            gap: 20px;
            padding: 0 14px;
            .two-col {
                flex-direction: row;
                justify-content: space-between;
                gap: 10px;
                .expiry-box {
                    max-width: none;
                    flex: 1 0 70%;
                }
            }
        }
        .cta {
            margin-top: 25px;
        }
        .form-n-summary {
            flex-direction: column;
            gap: 0;
            .summary-box {
                margin: 30px 0;
                h5 {
                    font-size: 19px;
                    line-height: 28px;
                    font-weight: bold;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #ddedd0;
                }
                .plan-detail {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    .plan-left {
                        padding-bottom: 0;
                        border-bottom: 0 none;
                        .plan-title {
                            font-size: 16px;
                            line-height: 29px;
                            font-weight: 600;
                        }
                        .fee {
                            font-weight: 600;
                        }
                        .tooltip-box {
                            display: none;
                            font-size: 14px;
                            padding: 20px;
                            box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                            background: white;
                            border-radius: 8px;
                            position: absolute;
                            font-weight: 400;
                            right: 0;
                            line-height: 17px;
                            width: 190px;
                        }
                        .tooltip-ic {
                            font-size: 18px;
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            line-height: 12px;
                            color: ${({ theme }) => theme.colors.title_green};
                            &:hover + .tooltip-box {
                                display: block;
                            }
                        }
                    }
                    .plan-right {
                        padding-top: 0;
                        .total-due {
                            .due-text {
                                font-weight: 400;
                            }
                            .amount {
                                margin-top: 4px;
                                font-size: 28px;
                                line-height: 38px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .right-side {
                order: -1;
            }
        }
    }
    @media (max-width: 850px) {
        max-width: none;
        width: 100%;
        .form-section {
            .two-col {
                .cvc-box {
                    max-width: none;
                    flex: 1 1 115px;
                }
            }
        }
    }
`;
const useOptions = () => {
    const theme = useTheme();
    const options = useMemo(() => ({
        showIcon: true,
        style: {
            base: {
                fontSize: '16px',
                color: theme.colors.title_green,
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: theme.colors.lightGray,
                },
            },
        },
    }));

    return options;
};
const CheckoutForm = ({ planID, handlePaymentStatus, updateSubscriptionPlan, paymentAmount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [paymentError, setPaymentError] = useState(false);
    const [tcCheck, setTcCheck] = useState(false);
    const [tcError, setTcError] = useState(false);
    const [isProcessing, setProcessingTo] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [validationError, setValidationError] = useState(false);

    const termsLink = (
        <Link to="/terms-of-service" target="_blank">
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </Link>
    );
    const privacyLink = (
        <Link to="/privacy-policy" target="_blank">
            <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </Link>
    );

    const consentLabel = (
        <FormattedMessage
            id="Subscription.StepForm.MakePayment.TermsPrivacy"
            values={{ termsLink, privacyLink }}
        />
    );

    const onSubmit = async (values, actions) => {
        const { cardName } = values;
        handlePaymentStatus(false);
        setProcessingTo(true);
        setPaymentError(false);

        setValidationError(false);

        setTcError(false);
        if (!tcCheck) {
            setTcError(true);
            setProcessingTo(false);
        }
        if (tcCheck) {
            try {
                // if (!planID) {
                //     setProcessingTo(false);
                //     return;
                // }
                let token = { id: '' };
                let response = { token: {} };

                // Get the individual Card Elements
                const cardNumberElement = elements.getElement(CardNumberElement);

                response = await stripe.createToken(cardNumberElement);

                console.log('toenres', planID);
                if (response.error) {
                    setProcessingTo(false);
                    setValidationError(true);
                    return;
                }
                token = { ...token, ...response.token };
                coreAPI
                    .subscribeToLandscapePlan(planID, token.id, paymentAmount)
                    .then(async response => {
                        if (response.data) {
                            console.log('rsponse.data', response.data);
                            if (
                                response.data.status === 'requires_action' ||
                                response.data.status === 'requires_payment_method'
                            ) {
                                // We perform 3D Secure authentication
                                const { paymentIntent, error } = await stripe.confirmCardPayment(
                                    response.data.clientSecret,
                                    {
                                        payment_method: {
                                            card: elements.getElement(CardNumberElement), // Pass Card Element
                                            billing_details: {
                                                name: cardName, // Get customer name from the form
                                            },
                                        },
                                    }
                                );
                                if (error) {
                                    alert('Error in payment, please try again later', error);
                                    setProcessingTo(false);
                                    setPaymentError(true);
                                }
                                if (paymentIntent.status === 'succeeded')
                                    updateSubscriptionPlan(
                                        response.data.subscription.subscriptionId
                                    );
                                handlePaymentStatus(true);
                                setPaymentError(false);
                                setProcessingTo(false);
                                //const res2 = await axios.get(`http://localhost:5000/check/${res.data.id}`);
                                //alert(`Payment successful, payment ID - ${res.data.id}`);
                            } else if (response.data.status === 'requires_payment_method') {
                                //alert('Error in payment, please try again later');
                                setProcessingTo(false);
                                setPaymentError(true);
                            } else if (response.data.status == 'succeeded') {
                                updateSubscriptionPlan(response.data.subscription.subscriptionId);
                                handlePaymentStatus(true);
                                setProcessingTo(false);
                                setPaymentError(false);
                            } else {
                                setProcessingTo(false);
                                setPaymentError(true);
                            }
                        } else {
                            setProcessingTo(false);
                            setPaymentError(true);
                        }
                    })
                    .catch(e => {
                        setProcessingTo(false);
                        console.error(e);
                        setPaymentError(true);
                    });
            } catch (e) {
                setProcessingTo(false);
                console.error(e);
                setPaymentError(true);
            }
        }
    };

    const validationSchema = Yup.object({});

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ cardName: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {formik => {
                setIsFormValid(formik.isValid);
                return (
                    <Form onChange={() => setPaymentError(false)}>
                        <div className="form-section flex flex-col">
                            <div className="input-box-section">
                                <label for="cardnum" className="small">
                                    <FormattedMessage id="Subscription.StepForm.MakePayment.CardNumber" />
                                </label>
                                <div className="card-num-box">
                                    <CardNumberElement
                                        options={options}
                                        onChange={event => {
                                            console.log('CardNumberElement [change]', event);
                                            setValidationError(false);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="two-col flex space-between">
                                <div className="input-box-section expiry-box">
                                    <label for="expiry-date" className="small">
                                        <FormattedMessage id="Subscription.StepForm.MakePayment.CardExpiry" />
                                    </label>
                                    <div className="card-num-box">
                                        <CardExpiryElement
                                            options={options}
                                            onChange={event => {
                                                setValidationError(false);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-box-section cvc-box">
                                    <label for="cvc" className="small">
                                        <FormattedMessage id="Subscription.StepForm.MakePayment.CardCvc" />
                                    </label>
                                    <div className="card-num-box">
                                        <CardCvcElement
                                            options={options}
                                            onChange={event => {
                                                setValidationError(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="one-col">
                                <CheckBoxInfo
                                    labelName={consentLabel}
                                    labelId="consent"
                                    checkboxSection="consent-box"
                                    isChecked={tcCheck}
                                    onChange={() => setTcCheck(!tcCheck)}
                                />
                            </div>
                            <div className="error-text small">
                                {!tcCheck && tcError && (
                                    <FormattedMessage id="NewFriendsPage.Setup.Payment.tcErrMsg" />
                                )}
                                {paymentError && (
                                    <>
                                        <p>
                                            <FormattedMessage id="PaymentError.title" />
                                        </p>
                                        <p>
                                            <FormattedMessage id="PaymentError.subtitle" />
                                        </p>
                                    </>
                                )}
                                {validationError && (
                                    <FormattedMessage id="CardValidationError.title" />
                                )}
                            </div>
                            {/* The message card goes here */}

                            <div className={`message-card ${isProcessing ? 'visible' : ''}`}>
                                <FormattedHTMLMessage
                                    id="Subscription.StepForm.MakePayment.Processing"
                                    values={{
                                        p: chunks => <p>{chunks}</p>,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="cta mx-auto">
                            <OrangeBtn
                                type="submit"
                                disabled={isProcessing || !stripe}
                                className={isProcessing ? 'disabled' : null}
                            >
                                <FormattedMessage id="Subscription.StepForm.MakePayment_PayJoin" />
                            </OrangeBtn>
                            <div className="powerby relative flex fee-n-box justify-center items-center small">
                                Powered by Stripe.
                                <Link className="tooltip-ic">
                                    <IoIosInformationCircle />
                                </Link>
                                <div className="tooltip-box">
                                    <FormattedMessage id="Subscription.StepForm.MakePayment_ToolTip" />
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

const MakePayment = ({ classname }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(false);

    const selectedPlan = useSelector(state => state.subscription.selectedPlan);
    const selectedLandscape = useSelector(state => state.subscription.selectedLandscape);
    const planID = useSelector(state => state.subscription.planId);
    console.log('selectedPlan', selectedPlan, planID);

    const updateSubscriptionPlan = subscriptionId => {
        setSubscriptionId(subscriptionId);
    };

    return (
        <>
            {paymentStatus && subscriptionId ? (
                <PaymentSuccess
                    currentSelectedPlan={selectedPlan}
                    selectedLandScape={selectedLandscape}
                    subscriptionId={subscriptionId}
                />
            ) : (
                <StepForm plan={selectedPlan.plan}>
                    <StepFourSection className={`flex flex-col justify-space-between ${classname}`}>
                        <div className="form-n-summary flex">
                            <div className="left-side">
                                <div className="form-title">
                                    <FormattedMessage id="Subscription.StepForm.PaymentDetails" />

                                    {selectedPlan.plan !== 'oneoff' && (
                                        <div className="title-info">
                                            <div className="text-left hide-mobile">
                                                <FormattedMessage id="Subscription.StepForm.MakePayment_Desc" />
                                            </div>
                                            <div className="show-mobile text-center">
                                                <FormattedMessage id="Subscription.StepForm.MakePayment_Desc_Mobile" />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                                    <div className="summary-box show-tab">
                                        <h5 className="small">
                                            <FormattedMessage id="Subscription.StepForm.MakePayment_Summary" />
                                        </h5>

                                        <div className="plan-detail">
                                            <div className="plan-left">
                                                <div className="plan-title">
                                                    {selectedPlan.planTitle}
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="fee small">
                                                        $
                                                        {selectedPlan.plan === 'oneoff'
                                                            ? selectedPlan.amount
                                                            : selectedPlan.planCost / 300}
                                                        <Link className="tooltip-ic">
                                                            <IoIosInformationCircle />
                                                        </Link>
                                                        <div className="tooltip-box">
                                                            {selectedPlan.plan === 'oneoff' ? (
                                                                <FormattedMessage id="Subscription.StepForm.OneOffStripeToolTip" />
                                                            ) : (
                                                                <FormattedMessage id="Subscription.StepForm.StripeToolTip" />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedPlan.plan !== 'oneoff' && (
                                                <div className="plan-right">
                                                    <div className="total-due">
                                                        <div className="due-text small">
                                                            <FormattedMessage id="Subscription.StepForm.MakePayment_Quarter" />
                                                        </div>
                                                        <div className="amount">
                                                            ${selectedPlan.planCost / 100}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="plan-detail">
                                            <div className="plan-left">
                                                {selectedPlan.plan !== 'oneoff' && (
                                                    <div className="fee-in ">
                                                        <div className="text small">
                                                            <FormattedMessage id="Subscription.StepForm.Impact" />
                                                        </div>
                                                        <div className="fee small">
                                                            {selectedPlan.impactList[0].listTxt} /{' '}
                                                            <FormattedMessage id="Subscription.StepForm.Year" />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="fee-in ">
                                                    <div className="text small">
                                                        <FormattedMessage id="Subscription.StepForm.MakePayment_Landscape" />
                                                    </div>
                                                    <div className="fee small">
                                                        {selectedLandscape.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                        planID={planID}
                                        handlePaymentStatus={setPaymentStatus}
                                        updateSubscriptionPlan={updateSubscriptionPlan}
                                        paymentAmount={
                                            selectedPlan.plan === 'oneoff'
                                                ? selectedPlan.amount
                                                : selectedPlan.planCost / 300
                                        }
                                    />
                                </Elements>
                            </div>
                            <div className="right-side">
                                <div className="summary-box hide-tab">
                                    <h5 className="small">Summary</h5>
                                    <div className="plan-detail">
                                        {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                                            <div className="plan-left">
                                                {selectedPlan.plan !== 'oneoff' && (
                                                    <img
                                                        src={selectedPlan.planImage}
                                                        className="hide-tab"
                                                        alt="plan icon"
                                                    />
                                                )}
                                                <div className="plan-title">
                                                    {selectedPlan.planTitle}
                                                </div>
                                                <div className="flex fee-in justify-space-between">
                                                    <div className="text small">
                                                        {selectedPlan.plan === 'oneoff' ? (
                                                            <FormattedMessage id="Subscription.StepForm.OneOffAmount" />
                                                        ) : (
                                                            <FormattedMessage id="Subscription.StepForm.MonthlyFee" />
                                                        )}
                                                    </div>
                                                    <div className="fee small">
                                                        $
                                                        {selectedPlan.plan === 'oneoff'
                                                            ? selectedPlan.amount
                                                            : selectedPlan.planCost / 300}
                                                        <Link className="tooltip-ic">
                                                            <IoIosInformationCircle />
                                                        </Link>
                                                        <div className="tooltip-box">
                                                            {selectedPlan.plan === 'oneoff' ? (
                                                                <FormattedMessage id="Subscription.StepForm.OneOffStripeToolTip" />
                                                            ) : (
                                                                <FormattedMessage id="Subscription.StepForm.StripeToolTip" />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectedPlan.plan !== 'oneoff' && (
                                                    <div className="fee-in flex items-center justify-space-between">
                                                        <div className="text small">
                                                            <FormattedMessage id="Subscription.StepForm.Impact" />
                                                        </div>
                                                        <div className="fee small">
                                                            {selectedPlan.impactList[0].listTxt} /{' '}
                                                            <FormattedMessage id="Subscription.StepForm.Year" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {selectedLandscape &&
                                            Object.keys(selectedLandscape).length > 0 && (
                                                <>
                                                    <div className="plan-left">
                                                        <img
                                                            src={landscapeIcon}
                                                            className="landscape-img hide-tab"
                                                            alt="landscape icon"
                                                        />

                                                        <div className="plan-title">
                                                            {selectedLandscape.name}{' '}
                                                            <FormattedMessage id="Subscription.StepForm.MakePayment_Landscape" />
                                                        </div>
                                                    </div>
                                                    {selectedPlan.plan !== 'oneoff' && (
                                                        <div className="plan-right">
                                                            <div className="total-due">
                                                                <div className="due-text small">
                                                                    <FormattedMessage id="Subscription.StepForm.MakePayment_Quarter" />
                                                                </div>
                                                                <div className="amount">
                                                                    ${selectedPlan.planCost / 100}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="step-nav flex mx-auto">
                            <div className="bullet"></div>
                            <div className="bullet"></div>
                            <div className="bullet"></div>
                            <div className="bullet current"></div>
                        </div>
                    </StepFourSection>
                </StepForm>
            )}
        </>
    );
};

//export default injectStripe(MakePayment);
export default injectIntl(withRouter(MakePayment));
