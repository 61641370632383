import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';

import { useSelector, useDispatch } from 'react-redux';
import {
    setSelectedPlan,
    setSelectedPlanOneTimeAmount,
} from '../../ducks/subscriptionReducer.duck';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik, Form, ErrorMessage } from 'formik';
import TextField from '../../newComponents/Form/TextField';

import { plans } from '../../util/paymentData';

import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { ExternalLink } from '../../components';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SelectPlanBox from '../../newComponents/SelectPlanBox';
import { IoIosInformationCircle } from 'react-icons/io';

import StepForm from './StepForm';

const StepTwoSection = styled.section`
    margin-top: 60px;
    max-width: 630px;
    .fee-n-box {
        .fee {
            line-height: 180%;
        }
        .tooltip-box {
            top: 35px;
            right: 0;
            background-color: white;
            z-index: 9;
        }
    }
    .plan-group {
        gap: 20px;
        .planradio {
            position: relative;
            .radio-box {
                .checkmark {
                    right: 8px;
                    left: auto;
                    top: 8px;
                }
                .plan-box {
                    max-width: 196px;
                    position: relative;
                    top: -18px;
                    &:hover {
                        border: 1px solid ${({ theme }) => theme.colors.light_green};
                    }
                }
                input:checked + .plan-box {
                    border: 1px solid #6ea44c;
                }
            }
        }
        /* Add hover effect for the checkmark */
        .planradio:hover .checkmark {
            border: 1px solid ${({ theme }) => theme.colors.light_green};
        }
    }
    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 20px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
        .login-cta {
            line-height: 150%;
            margin-top: 27px;
            a {
                font-weight: 600;
                color: ${({ theme }) => theme.colors.title_green};
                &:hover {
                    text-decoration: underline;
                }
            }
            ul {
                margin-top: 10px;
                margin-left: 30px;
                text-align: left;
                width: 100%;
                li {
                    list-style-type: disc;
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .fee-n-box {
            margin-left: auto;
            margin-right: auto;
            .tooltip-box {
                bottom: 35px;
                top: auto;
            }
        }
        .plan-group {
            flex-wrap: wrap;
            gap: 20px;
            .planradio {
                position: relative;
                .radio-box {
                    .checkmark {
                        right: 8px;
                        left: auto;
                        top: 8px;
                    }
                    .plan-box {
                        position: relative;
                        top: -18px;
                    }
                }
            }
        }
    }
    @media (max-width: 500px) {
        .plan-group {
            .planradio {
                .radio-box {
                    .plan-box {
                        max-width: none;
                    }
                }
            }
        }
    }
`;

const OneTimePayment = ({ classname, intl }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPlan = useSelector(state => state.subscription.selectedPlan);
    const planTarget = useSelector(state => state.subscription.planTarget);
    const formikRef = useRef(); // Create a ref to access Formik's methods

    const [showError, setShowError] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(selectedPlan.amount || 0);

    const handleSubmit = () => {
        setShowError(false);
        if (selectedPlan) {
            dispatch(setSelectedPlanOneTimeAmount(paymentAmount));
            history.push('/subscription/select-landscape');
        } else {
            setShowError(true);
        }
    };

    const isPlanSelected = () => {
        return selectedPlan && Object.keys(selectedPlan).length > 0;
    };
    const amountLabel = intl.formatMessage({
        id: 'Subscription.OneOffForm.amountLabel',
    });
    const amountPlaceholder = intl.formatMessage({
        id: 'Subscription.OneOffForm.amountPlaceholder',
    });
    console.log('plantargee', planTarget);
    console.log('selectedPlan', selectedPlan);

    const supportLink = (
        <ExternalLink href={`mailto:${process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}`}>
            Contact
        </ExternalLink>
    );

    useEffect(() => {
        if (!selectedPlan.plan) {
            console.log('inuseffect', selectedPlan);
            dispatch(
                setSelectedPlan({
                    planTitle: 'One Time Payment',
                    plan: 'oneoff',
                    planType: 'unico',
                    amount: 0,
                })
            );
        }
    }, []);

    return (
        <StepForm plan="oneoff" step={2} isNextDisabled={!isPlanSelected()} formikRef={formikRef}>
            <StepTwoSection className={`mx-auto flex flex-col ${classname}`}>
                {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                    <div className="fee-n-box w-full box-shadow absolute hide-tab">
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">
                                <FormattedMessage id="Subscription.StepForm.OneOffAmount" />
                            </div>
                            <div className="big-txt flex items-center small">
                                ${paymentAmount}{' '}
                                <Link className="tooltip-ic">
                                    <IoIosInformationCircle />
                                </Link>
                                <div className="tooltip-box">
                                    <FormattedMessage id="Subscription.StepForm.OneOffStripeToolTip" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="form-title">
                    <FormattedMessage id="Subscription.StepForm.Step2-onetime.title" />

                    <div className="step-text small">
                        <FormattedMessage id="Subscription.StepForm.Step2-onetime.desc" />
                    </div>
                </div>
                <form>
                    <div className="plan-group flex justify-center">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize={true}
                            initialValues={{ amount: selectedPlan.amount }}
                            validationSchema={Yup.object({
                                amount: Yup.number()
                                    .required(
                                        <FormattedMessage id="Subscription.StepForm.OneOffSupport.amount.errMsg" />
                                    )
                                    .max(1000000, 'Allowed Upto 1 mn USD')
                                    .min(1),
                            })}
                            onSubmit={handleSubmit}
                            validateOnMount={true}
                        >
                            {formik => {
                                setIsFormValid(formik.isValid);
                                setPaymentAmount(formik.values.amount);
                                return (
                                    <Form onChange={() => setShowError(false)}>
                                        <div className="form-section flex flex-col">
                                            <div className="one-col">
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="number"
                                                        label={amountLabel}
                                                        name="amount"
                                                        placeholder={amountPlaceholder}
                                                        aria-describedby="amount in USD"
                                                        className={`form-control  ${formik.touched
                                                            .amount &&
                                                            formik.errors.amount &&
                                                            'input-error'}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cta mx-auto">
                                            <OrangeBtn
                                                type="submit"
                                                disabled={!formik.isValid}
                                                className={!formik.isValid ? 'disabled' : null}
                                            >
                                                <FormattedMessage id="NewPartnersPage.Setup.Account.continue" />
                                            </OrangeBtn>

                                            <div className="login-cta small">
                                                <FormattedMessage
                                                    id="Subscription.StepForm.OneOffSupport"
                                                    values={{ supportLink }}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                    {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                        <div className="fee-n-box w-full box-shadow relative show-tab">
                            <div className="fee flex items-center justify-space-between">
                                <div className="small-txt small">
                                    <FormattedMessage id="Subscription.StepForm.OneOffAmount" />
                                </div>
                                <div className="big-txt flex items-center small">
                                    ${paymentAmount}{' '}
                                    <Link className="tooltip-ic">
                                        <IoIosInformationCircle />
                                    </Link>
                                    <div className="tooltip-box">
                                        <FormattedMessage id="Subscription.StepForm.OneOffStripeToolTip" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="cta mx-auto">
                        <OrangeBtn
                            type="button"
                            onClick={handlePlanSubmit}
                            disabled={!isPlanSelected()}
                            className={!isPlanSelected() ? 'disabled' : null}
                        >
                            <FormattedMessage id="Subscription.StepForm.SelectContinue" />
                        </OrangeBtn>
                    </div> */}
                    {showError && (
                        <div className="error-text small">
                            <FormattedMessage id="MultiStep.ConfirmPlan.planSelectionFailed" />
                        </div>
                    )}
                </form>
                <div className="step-nav flex mx-auto">
                    <div className="bullet"></div>
                    <div className="bullet current"></div>
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                </div>
            </StepTwoSection>
        </StepForm>
    );
};

export default compose(injectIntl)(OneTimePayment);
