import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';

import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';

const MainForm = styled.section`
    margin: 0 0;
    display: flex;
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        display: block;
    }
`;
const StepSection = styled.section`
    width: 100%;
    max-width: 36%;
    padding: 70px 20px;
    background: ${({ theme }) => theme.colors.title_green};

    .step-each {
        max-width: 258px;
        margin-top: 58px;
        position: relative;
        left: 25px;
        .step-title,
        .step-text {
            color: white;
        }
        .step-title {
            color: white;
            font-weight: 600;
            line-height: 28px;
        }
        .step-text {
            line-height: 150%;
        }
        &:first-child {
            margin-top: 0;
            height: 48px;
        }
        &::before {
            position: absolute;
            content: '•';
            color: white;
            font-size: 77px;
            line-height: 0;
            height: 13px;
            padding: 10px 5px 10px;
            border-radius: 50%;
            border: 1px solid #fff;
            left: -59px;
            top: 0;
        }
        &::after {
            position: absolute;
            content: '';
            background: white;
            width: 1px;
            height: 75px;
            top: 35px;
            left: -43px;
        }
        &:last-child {
            &::after {
                content: none;
            }
        }
    }
    .step-one {
        &::after {
            // height: 68px;
            // top: 62px;
        }
    }
    .step-two {
        margin-top: 62px;
    }
    .step-three {
        margin-top: 62px;
        height: 70px;
    }
    .step-four {
        margin-top: 40px;
    }
    .step-each.start {
        &::before {
            background: white;
            color: #699d49;
        }
    }
    .complete-check {
        &::before {
            background: #fff
                url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12' fill='none'%3E%3Cpath d='M1 5L5.29453 9.72399C5.6786 10.1465 6.33785 10.1621 6.74158 9.75842L15.5 1' stroke='%236EA44C' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E")
                no-repeat center center;
            content: '';
            width: 22px;
            height: 12px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        display: none;
    }
`;
const FormSection = styled.section`
    flex: 1 1 auto;
    padding: 75px 5% 50px;
    position: relative;

    .formnextprev {
        top: -50px;
        button {
            line-height: 180%;
            font-weight: 600;
            padding: 5px;
            gap: 4px;
            border: 0;
            background: none;
            cursor: pointer;
            color: ${({ theme }) => theme.colors.title_green};
        }
        .prev-btn {
            align-self: flex-start;
        }
        .next-btn {
            position: absolute;
            right: 0;
            top: 0;
        }
        .disable {
            color: #bababa;
            cursor: default;
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            top: 13px;
        }
    }
    .form-title {
        font-size: 19px;
        line-height: 27px;
        font-weight: 600;
        margin-bottom: 20px;
        .title-info {
            font-weight: 400;
            font-size: 14px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        margin-top: 66px;
        padding: 20px;
        .form-title {
            text-align: center;
            font-size: 16px;
            line-height: 180%;
            .title-info {
                padding: 0 15px;
            }
        }
        .step-nav {
            order: -1;
            margin-top: 0;
            margin-bottom: 40px;
        }
    }
`;

const StepForm = ({ plan, step, location, history, user, children, isNextDisabled, formikRef }) => {
    // styled

    const steps = [
        { path: '/subscription/signup', title: 'Your details' },
        { path: '/subscription/confirm-plan', title: 'Select your plan' },
        { path: '/subscription/select-landscape', title: 'Select your landscape' },
        { path: '/subscription/payment', title: 'Add payment details' },
    ];
    if (plan === 'oneoff') {
        steps[1].path = '/subscription/onetime-payment';
    }
    const currentStepIndex = steps.findIndex(step => step.path === location.pathname);

    const handleNext = () => {
        if (currentStepIndex < steps.length - 1 && !isNextDisabled) {
            if (formikRef && formikRef.current) {
                formikRef.current.submitForm();
            } else {
                history.push(steps[currentStepIndex + 1].path);
            }
        }
    };

    const handlePrev = () => {
        if (currentStepIndex > 0) {
            history.push(steps[currentStepIndex - 1].path);
        }
    };
    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <MainForm>
                <StepSection>
                    {/* check class - complete-check */}

                    <div
                        className={`step-one step-each ${
                            0 < currentStepIndex ? 'complete-check' : ''
                        } ${0 === currentStepIndex ? 'start' : ''} relative mx-auto`}
                    >
                        <div className="step-title">
                            <FormattedMessage id="Subscription.StepForm.Step1.title" />
                        </div>
                        <div className="step-text small">
                            <FormattedMessage id="Subscription.StepForm.Step1.desc" />
                        </div>
                    </div>
                    {/* white bg green dot class - start */}

                    <div
                        className={`step-two step-each ${
                            1 < currentStepIndex ? 'complete-check' : ''
                        } ${1 === currentStepIndex ? 'start' : ''} relative mx-auto`}
                    >
                        <div className="step-title">
                            {plan === 'oneoff' ? (
                                <FormattedMessage id="Subscription.StepForm.Step2-onetime.title" />
                            ) : (
                                <FormattedMessage id="Subscription.StepForm.Step2.title" />
                            )}
                        </div>
                        <div className="step-text small">
                            {plan === 'oneoff' ? (
                                <FormattedMessage id="Subscription.StepForm.Step2-onetime.desc_short" />
                            ) : (
                                <FormattedMessage id="Subscription.StepForm.Step2.desc" />
                            )}
                        </div>
                    </div>
                    <div
                        className={`step-three step-each ${
                            2 < currentStepIndex ? 'complete-check' : ''
                        } ${2 === currentStepIndex ? 'start' : ''} relative mx-auto`}
                    >
                        <div className="step-title">
                            <FormattedMessage id="Subscription.StepForm.Step3.title" />
                        </div>
                        <div className="step-text small">
                            <FormattedMessage id="Subscription.StepForm.Step3.desc" />
                        </div>
                    </div>
                    <div
                        className={`step-four step-each ${
                            3 < currentStepIndex ? 'complete-check' : ''
                        } ${3 === currentStepIndex ? 'start' : ''} relative mx-auto`}
                    >
                        <div className="step-title">
                            <FormattedMessage id="Subscription.StepForm.Step4.title" />
                        </div>
                        <div className="step-text small">
                            <FormattedMessage id="Subscription.StepForm.Step4.desc" />
                        </div>
                    </div>
                </StepSection>
                <FormSection>
                    <div className="formnextprev justify-space-between relative w-full flex items-center">
                        <button
                            className={`prev-btn absolute flex items-center ${
                                currentStepIndex === 0 ? 'hidden' : ''
                            }`}
                            onClick={handlePrev}
                        >
                            <IoIosArrowBack />{' '}
                            <small>
                                <FormattedMessage id="Subscription.StepForm.previous" />
                            </small>
                        </button>
                        <button
                            className={`next-btn absolute flex items-center ${
                                currentStepIndex === steps.length - 1 ? 'hidden' : ''
                            } ${isNextDisabled ? 'disable' : ''}`}
                            onClick={handleNext}
                        >
                            <small>
                                <FormattedMessage id="Subscription.StepForm.next" />
                            </small>{' '}
                            <IoIosArrowForward />
                        </button>
                    </div>
                    {children}
                </FormSection>
            </MainForm>
            <Footer history={history} />
        </Page>
    );
};
const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});
export default connect(mapStateToProps)(withRouter(StepForm));
