import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Page, NamedRedirect } from '../../components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import thankYouBg from '../../assets/newAssets/thankyoubg.webp';

import { addOrder, deleteCart } from '../../coreApi';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateUser } from '../../coreApi';

const FinalStepSection = styled.section`
    background: #f8fbf6;
    padding: 60px 20px;
    width: 100%;
    .thankyou-box {
        background-color: white;
        border-radius: 20px;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
        max-width: 630px;
        padding: 39px 56px 208px;
        .close-btn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            right: 17px;
            top: 17px;
            color: #004d37;
            font-size: 18px;
        }
        .thankyou-bg {
            left: -60px;
            min-width: 717px;
        }
        .cta {
            margin-top: 16px;
        }
    }
    @media (max-width: 500px) {
        .thankyou-box {
            padding-bottom: 120px;
            .thankyou-bg {
                left: -45px;
                min-width: 120%;
                width: 100%;
                position: absolute;
            }
            .cta {
                margin-top: 16px;
            }
        }
    }
`;
const PaymentSuccess = ({
    location,
    history,
    user,
    currentSelectedPlan,
    selectedLandScape,

    subscriptionId,
}) => {
    const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;
    const { planCost, plan, amount } = currentSelectedPlan;
    const { name } = selectedLandScape;
    const dispatch = useDispatch();
    const order = {
        orderId: subscriptionId,
        customer: {
            id: user && user.email,
            email_address: user && user.email,
            opt_in_status: true,
            first_name: user && user.firstName,
            last_name: user && user.lastName,
        },
        order: [
            {
                id: user && user.email,
                product_id:
                    currentSelectedPlan && currentSelectedPlan.plan == 'polen'
                        ? 'polen'
                        : `${currentSelectedPlan.plan}_${currentSelectedPlan.planType}`,
                product_variant_id:
                    currentSelectedPlan && currentSelectedPlan.plan == 'polen'
                        ? 'polen'
                        : `${currentSelectedPlan.plan}_${currentSelectedPlan.planType}`,
                quantity: 1,
                price: currentSelectedPlan.planCost / 100,
            },
        ],
    };

    useEffect(() => {
        const newCount = user && (parseInt(user.cartCount) + 1).toString();
        const cartId = user.email + '_' + user.cartCount;
        addOrder(order).then(() => {
            deleteCart(cartId).then(() => {
                updateUser({ cartCount: newCount }).then(() => {
                    dispatch(fetchCurrentUser());
                });
            });
        });
    }, []);

    useEffect(() => {
        googleAnalytics &&
            window.gtag('event', 'conversion', {
                send_to: 'AW-10887015223/t5xTCJXbi8IDELfWqsco',
                value: planCost / 100,
                currency: 'USD',
            });
    }, []);

    useEffect(() => {
        googleAnalytics &&
            window.gtag('event', 'plan_subscription', {
                send_to: 'G-34FQJZ2P9G',
                plan: currentSelectedPlan.plan,
                price: currentSelectedPlan.planCost / 100,
                landscape: name,
            });
    }, []);

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <FinalStepSection className={`mx-auto flex flex-col`}>
                <div className="thankyou-box mx-auto text-center relative">
                    <Link to="/" className="absolute close-btn flex items-center justify-center">
                        <IoClose />
                    </Link>
                    <div className="title-box text-center">
                        <h2>
                            <FormattedMessage id="Subscription.Success.Title" />
                        </h2>
                    </div>

                    <FormattedHTMLMessage
                        id="Subscription.Success.Message"
                        values={{
                            p: chunks => <p>{chunks}</p>,
                        }}
                    />
                    <div>
                        <h4>
                            <FormattedMessage id="Subscription.Success.Summary" />
                        </h4>
                    </div>
                    <p>
                        {plan === 'oneoff' ? (
                            <FormattedMessage
                                id="Subscription.PaymentSuccess.OneTime.CongratsMsg"
                                values={{
                                    landscape: <>{name}</>,
                                    amount: <>{amount}</>,
                                    plan: <>{plan}</>,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="NewFriendsPage.Setup.PaymentSucc.congratesMsg"
                                values={{
                                    landscape: <>{name}</>,
                                    amount: <>{planCost / 300}</>,
                                    plan: <>{plan}</>,
                                }}
                            />
                        )}
                    </p>
                    <div className="cta text-center">
                        <OrangeBtn>
                            <Link to="/">
                                <FormattedMessage id="Subscription.Success.Discover" />
                            </Link>
                        </OrangeBtn>
                    </div>
                    <img src={thankYouBg} alt="thank you" className="w-full thankyou-bg absolute" />
                </div>
            </FinalStepSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              currentRole: storeState.user.currentUser.currentRole,
              cartCount: storeState.user.currentUser.cartCount,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(PaymentSuccess));
