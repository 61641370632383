// reducers/subscriptionReducer.js
const initialState = {
    selectedPlan: {},
    planTarget: '',
    selectedLandscape: {},
    planId: '',
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PLAN_TARGET':
            return { ...state, planTarget: action.payload };

        case 'SET_SELECTED_PLAN':
            return { ...state, selectedPlan: action.payload };

        case 'SET_SELECTED_LANDSCAPE':
            return { ...state, selectedLandscape: action.payload };
        case 'SET_PLAN_ID':
            return { ...state, planId: action.payload };
        case 'SET_PLAN_ONETIME_AMOUNT':
            return {
                ...state,
                selectedPlan: {
                    ...state.selectedPlan, // Keep other properties of selectedPlan intact
                    amount: action.payload, // Update the amount key
                },
            };
        default:
            return state;
    }
};

export const setSelectedPlan = selectedPlan => ({
    type: 'SET_SELECTED_PLAN',
    payload: selectedPlan,
});

export const setSelectedLandscape = selectedLandscape => ({
    type: 'SET_SELECTED_LANDSCAPE',
    payload: selectedLandscape,
});

export const setPlanTarget = planTarget => ({
    type: 'SET_PLAN_TARGET',
    payload: planTarget,
});

export const setPlanId = planId => ({
    type: 'SET_PLAN_ID',
    payload: planId,
});

export const setSelectedPlanOneTimeAmount = paymentAmount => ({
    type: 'SET_PLAN_ONETIME_AMOUNT',
    payload: paymentAmount,
});

export default subscriptionReducer;
